
  import {
    calculatePersonalDataCompleteness,
    calculateAddressesCompleteness,
    calculateCommunicationDataCompleteness,
    calculateAppearanceCompleteness,
    calculateBankDataCompleteness,
    calculateDocumentCompleteness,
    calculateImageCompleteness,
    calculateMobilityCompleteness,
    calculateTaxCompleteness,
    calculateWorkExperiencesCompleteness,
    calculatePromotionExperiencesCompleteness,
    calculateJobEmploymentCompleteness,
    calculateSchoolingCompleteness,
    calculateWorkPreferenceCompleteness,
    //calculateSkillsCompleteness,
    calculateLanguagesCompleteness,
    calculateSocialProfilesCompleteness,
    calculateVideoCompleteness,
    calculateActivitySkillsCompleteness,
  } from '@/helper/ProfileCompletenessHelper';

  export default {
    name: 'ProfileFormNavigation',
    props: {
      profile: {
        type: Object,
        default: () => ({}),
      },
      invalidGroups: {
        type: Object,
        default: () => ({}),
      },
    },
    data: () => ({
      hasProfile: false,
      formGroups: {
        'personal-data': {
          title: 'Persönliche Daten',
          icon: 'fal fa-address-card',
          a: 'personal-data',
          isComplete: false,
          route: {name: 'myData', hash: '#personal-data'},
        },
        addresses: {
          title: 'Adressen',
          icon: 'fal fa-envelope',
          a: 'addresses',
          isComplete: false,
          route: {name: 'myData', hash: '#addresses'},
        },
        'communication-data': {
          title: 'Kommunikationsdaten',
          icon: 'fal fa-comment-dots',
          a: 'communication-data',
          isComplete: false,
          route: {name: 'myData', hash: '#communication-data'},
        },
        'social-profiles': {
          title: 'Soziale Medien',
          icon: 'fal fa-hashtag',
          a: 'social-profiles',
          isComplete: false,
          route: {name: 'myData', hash: '#social-profiles'},
        },
        appearance: {
          title: 'Erscheinungsbild',
          icon: 'fal fa-eye',
          a: 'appearance',
          isComplete: false,
          route: {name: 'myData', hash: '#appearance'},
        },
        languages: {
          title: 'Sprachen',
          icon: 'fal fa-language',
          a: 'languages',
          isComplete: false,
          route: {name: 'myData', hash: '#languages'},
        },
        // skills: {
        //   title: 'Skills/Fähigkeiten',
        //   icon: 'fal fa-cogs',
        //   a: 'skills',
        //   isComplete: false,
        // },
        'work-preference': {
          title: 'Arbeitsvorlieben',
          icon: 'fal fa-paperclip',
          a: 'work-preference',
          isComplete: false,
          route: {name: 'myData', hash: '#work-preference'},
        },
        'job-employment': {
          title: 'Beruflicher Status',
          icon: 'fal fa-briefcase',
          a: 'job-employment',
          isComplete: false,
          route: {name: 'myData', hash: '#job-employment'},
        },
        schooling: {
          title: 'Schul- & Berufsausbildung',
          icon: 'fal fa-graduation-cap',
          a: 'schooling',
          isComplete: false,
          route: {name: 'myData', hash: '#schooling'},
        },
        'work-experiences': {
          title: 'Berufserfahrung',
          icon: 'fal fa-brain',
          a: 'work-experiences',
          isComplete: false,
          route: {name: 'myData', hash: '#work-experiences'},
        },
        'activity-skills': {
          title: 'Fähigkeiten / Kompetenzen',
          icon: 'fal fa-cogs',
          a: 'activity-skills',
          isComplete: false,
          route: {name: 'myData', hash: '#activity-skills'},
        },
        'promotion-experiences': {
          title: 'Aktionserfahrung',
          icon: 'fal fa-thumbtack',
          a: 'promotion-experiences',
          isComplete: false,
          route: {name: 'myData', hash: '#promotion-experiences'},
        },
        mobility: {
          title: 'Mobilität',
          icon: 'fal fa-car',
          a: 'mobility',
          isComplete: false,
          route: {name: 'myData', hash: '#mobility'},
        },
        tax: {
          title: 'Bürokratische Angaben',
          icon: 'fal fa-book',
          a: 'tax',
          isComplete: false,
          route: {name: 'myData', hash: '#tax'},
        },
        'bank-data': {
          title: 'Bankdaten',
          icon: 'fal fa-money-check',
          a: 'bank-data',
          isComplete: false,
          route: {name: 'myData', hash: '#bank-data'},
        },
        images: {
          title: 'Bilder',
          icon: 'fal fa-camera',
          a: 'images',
          isComplete: false,
          route: {name: 'myImages'},
        },
      },
    }),
    watch: {
      profile: {
        deep: true,
        handler() {
          this.calculateCompleteness();
        },
      },
    },
    created() {
      this.hasProfile = Object.keys(this.profile).length > 0;
      if (this.hasProfile) {
        this.calculateCompleteness();
      }
      if (this.$featureToggle.toggles['videos']) {
        this.formGroups.videos = {
          title: 'Videos',
          icon: 'fal fa-video',
          a: 'videos',
          isComplete: false,
          route: {name: 'myVideos'},
        };
        this.formGroups.documents = {
          title: 'Dokumente',
          icon: 'fal fa-paste',
          a: 'documents',
          isComplete: false,
          route: {name: 'myDocuments'},
        };
      } else {
        this.formGroups.documents = {
          title: 'Dokumente',
          icon: 'fal fa-paste',
          a: 'documents',
          isComplete: false,
          route: {name: 'myDocuments'},
        };
      }
    },
    mounted() {
      this.scrollTo(document.location.hash);
    },
    methods: {
      scrollTo(hash) {
        if (window.location.href.indexOf('meine-daten') > -1 && hash) {
          if (hash.length > 1) {
            const id = hash.substring(1);
            const element = document.getElementById(id);
            if (element) element.scrollIntoView({behavior: 'smooth'});
          }
        }
      },
      calculateCompleteness() {
        this.formGroups['personal-data'].isComplete =
          calculatePersonalDataCompleteness(this.profile);
        this.formGroups['addresses'].isComplete =
          calculateAddressesCompleteness(this.profile);
        this.formGroups['communication-data'].isComplete =
          calculateCommunicationDataCompleteness(this.profile);
        this.formGroups['social-profiles'].isComplete =
          calculateSocialProfilesCompleteness(this.profile);
        this.formGroups['appearance'].isComplete =
          calculateAppearanceCompleteness(this.profile);
        this.formGroups['languages'].isComplete =
          calculateLanguagesCompleteness(this.profile);
        // this.formGroups['skills'].isComplete = calculateSkillsCompleteness(
        //   this.profile
        // );
        this.formGroups['work-preference'].isComplete =
          calculateWorkPreferenceCompleteness(this.profile);
        this.formGroups['mobility'].isComplete = calculateMobilityCompleteness(
          this.profile
        );
        this.formGroups['job-employment'].isComplete =
          calculateJobEmploymentCompleteness(this.profile);
        this.formGroups['schooling'].isComplete =
          calculateSchoolingCompleteness(this.profile);
        this.formGroups['work-experiences'].isComplete =
          calculateWorkExperiencesCompleteness(this.profile);
        this.formGroups['activity-skills'].isComplete =
          calculateActivitySkillsCompleteness(this.profile);
        this.formGroups['promotion-experiences'].isComplete =
          calculatePromotionExperiencesCompleteness(this.profile);
        this.formGroups['tax'].isComplete = calculateTaxCompleteness(
          this.profile
        );
        this.formGroups['bank-data'].isComplete = calculateBankDataCompleteness(
          this.profile
        );
        this.formGroups['images'].isComplete = calculateImageCompleteness(
          this.profile
        );
        this.formGroups['videos'].isComplete = calculateVideoCompleteness(
          this.profile
        );
        this.formGroups['documents'].isComplete = calculateDocumentCompleteness(
          this.profile
        );
      },
    },
  };
